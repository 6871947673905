.container {
  height: 100vh;
  background-image: url("https://ik.imagekit.io/pwxm960evbp/tr:w-800/MES-AA/Site_Images/Home/landing_2__4SY-VFEH8.jpg?updatedAt=1628499272813");
  background-size: cover;
  padding: 40px;
}

.elementBg {
  background-color: rgba(52, 52, 52, 0.3);
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  height: 100%;
  color: #d7d7d7;
  opacity: 1;
}

.heading {
  display: flex;
  padding: 10px;
}

.reg {
  font-size: 2rem;
}

.mainTitle {
  font-size: var(--main-heading-size);
  margin: 0;
}

.secondaryTitle {
  text-align: center;
  font-size: var(--secondary-heading-size);
  margin: 0;
}

.registeredIcon {
  font-size: 1rem;
  vertical-align: super;
}

.registeredIconSmall {
  font-size: 0.8rem;
  vertical-align: super;
}

.subtitle {
  font-size: 2rem;
  letter-spacing: 1px;
  color: #ffffff;
}

.cta {
  font-family: "Nunito Sans";
  width: 200px;
  padding: 15px;
  font-size: x-large;
  font-weight: 900;
  text-transform: uppercase;
  background-color: #d7d7d7;
  color: var(--primary-color);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  letter-spacing: 1px;
  margin-top: 30px;
}

.cta:hover {
  background-color: #ffffff;
}

.chevronContainer {
  text-align: center;
  margin-top: -40px;
}

.chevronIcon {
  font-size: 2rem;
  text-align: center;
  color: #d7d7d7;
  cursor: pointer;
}

.chevronAnimate {
  font-size: 2.5rem;
  text-align: center;
  color: #d7d7d7;
  cursor: pointer;
  animation-name: animate-icon;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

@keyframes animate-icon {
  0% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-15px);
  }
}

.letterContainer {
  height: auto;
  padding: 30px 0;
}

.letter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 150px;
  letter-spacing: 0.2px;
  line-height: 1.6;
}

.letterQuote {
  font-size: 1.5rem;
}

.letterQuote::before {
  content: open-quote;
}

.letterQuote::after {
  content: close-quote;
}

.letterQuoteSecondary {
  width: 65%;
  text-align: right;
  margin-top: -10px;
}

.associationName {
  font-weight: bold;
}

.letterConclusion {
  font-weight: bold;
  font-size: 1.2rem;
}

.testimonials {
  background-image: linear-gradient(
    to bottom,
    var(--secondary-background),
    #ffffff
  );
}

.testimonialContainer {
  padding: 30px 0 50px 0;
}

.testimonialLink {
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%); */
  text-align: center;
  font-size: 1rem;
  color: #c87941;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  .secondaryTitle {
    font-size: 1.5rem;
    margin: 0;
  }

  .container {
    background-image: url("https://ik.imagekit.io/pwxm960evbp/tr:w-600/MES-AA/Site_Images/Home/landing-mobile_3zK4Yydm5T4.jpg?updatedAt=1628498910653");
  }
}

@media only screen and (max-width: 900px) {
  .headerContainer {
    min-width: 100%;
  }

  .mainTitle {
    font-size: var(--main-heading-size-mobile);
  }

  .secondaryTitle {
    font-size: var(--secondary-heading-size-mobile);
    margin: 0;
  }

  .heading {
    display: flex;
    padding: 5px;
  }

  .registeredIcon {
    font-size: 0.75rem;
  }

  .registeredIconSmall {
    font-size: 0.5rem;
    vertical-align: super;
  }

  .subtitle {
    font-size: 1.25rem;
  }

  .cta {
    width: 170px;
    padding: 12px;
    font-size: large;
  }

  .letterContainer {
    margin: 20px 0;
  }

  .letter {
    padding: 0 20px;
  }

  .letterQuote {
    font-size: 1.2rem;
  }
}
